import React from "react";
import {usePremium} from "../../menu/auth/is_loggedin";
import {config} from "../../../constants";


// body is 640px
//
export const AdinplayTall = () => {

    const premium = usePremium()


    function width() {
        return (window.innerWidth - 640) / 2;
    }

    if (premium) {
        return <div/>
    } else if (width() >= 300 && window.innerHeight >= 600) {
        return <div className={"adinplay-300x600"}>
            <AdinplayAd size={"300x600"}/>
        </div>
    } else if (width() >= 120 && window.innerHeight >= 600) {
        return <div className={"adinplay-160x600"}>
            <AdinplayAd size={"160x600"}/>
        </div>
    }
    return <div/>
}

export const AdinplaySquare = () => {
    const premium = usePremium()
    if (premium) {
        return <div/>
    }

    function width() {
        return (window.innerWidth - 640) / 2;
    }

    function height() {
        return window.innerHeight
    }

    if (height() > 900 && width() >= 300) {
        return <div className={"hidden w-[300px] h-[250px] md:inline-block"}>
            <AdinplayAd size={"300x250"}/>
        </div>
    }

    return <div/>
}
export const AdinplayBottom = () => {
    const premium = usePremium()

    function width() {
        return window.innerWidth
    }


    function ad() {
        if (premium || config.development) {
            return <div/>
        } else if (width() <= 768 && width() >= 640) {
            return <div className={"w-[728px] h-[90px] inline-block"}>
                <AdinplayAd size={"728x90"}/>
            </div>
        } else if (width() <= 768 && width() >= 320) {
            return <div className={"inline-block w-[320px] h-[50px]"}>
                <AdinplayAd size={"320x50"}/>
            </div>
        }
    }

    return <div className={"flex justify-center items-center"}>
        {ad()}
    </div>

    // } else if (width() >= 300) {
    //     return <div className={"adinplay-300x50"}>
    //         <AdinplayAd size={"300x50"}/>
    //     </div>
    // }

}


type Props = {
    size: string
}

export const AdinplayAd = React.memo(({size}: Props) => {

    React.useEffect(() => {
        // @ts-ignore
        window.aiptag.cmd.display.push(function () {
            // @ts-ignore
            aipDisplayTag.display('franticfanfic-com_' + size);
        })
    })


    return (
        <div id={'franticfanfic-com_' + size} className={'adinplay-' + size}/>
    )
})